const customCSSPropertiesMap: Record<string, string> = {
  offsetTop: '--awsui-offset-top-f9jj7p',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-f9jj7p',
  contentGapLeft: '--awsui-content-gap-left-f9jj7p',
  contentGapRight: '--awsui-content-gap-right-f9jj7p',
  contentHeight: '--awsui-content-height-f9jj7p',
  defaultMaxContentWidth: '--awsui-default-max-content-width-f9jj7p',
  defaultMinContentWidth: '--awsui-default-min-content-width-f9jj7p',
  footerHeight: '--awsui-footer-height-f9jj7p',
  headerHeight: '--awsui-header-height-f9jj7p',
  layoutWidth: '--awsui-layout-width-f9jj7p',
  mainOffsetLeft: '--awsui-main-offset-left-f9jj7p',
  maxContentWidth: '--awsui-max-content-width-f9jj7p',
  minContentWidth: '--awsui-min-content-width-f9jj7p',
  notificationsHeight: '--awsui-notifications-height-f9jj7p',
  overlapHeight: '--awsui-overlap-height-f9jj7p',
  navigationWidth: '--awsui-navigation-width-f9jj7p',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-f9jj7p',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-f9jj7p',
  splitPanelMinWidth: '--awsui-split-panel-min-width-f9jj7p',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-f9jj7p',
  toolsMaxWidth: '--awsui-tools-max-width-f9jj7p',
  toolsWidth: '--awsui-tools-width-f9jj7p',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-f9jj7p',
  contentScrollMargin: '--awsui-content-scroll-margin-f9jj7p',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-f9jj7p',
  flashbarStackIndex: '--awsui-flashbar-stack-index-f9jj7p',
};
export default customCSSPropertiesMap;
